<template>
  <!-- Table Container Card -->
  <b-overlay :show="loading" rounded="sm">
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="9"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div>
              <label for="">Por página</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector mr-1"
              />
            </div>

            <div>
              <b-button
                variant="success"
                class="btn-icon mr-1 mt-2"
                @click="get"
              >
                <feather-icon icon="RefreshCwIcon" size="16" />
              </b-button>
            </div>

            <!--
            <b-button class="mt-1" variant="primary">
              <feather-icon icon="PlusIcon" size="16" />
              Nueva factura
            </b-button>-->
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="search"
                @keyup.enter="get"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refNoteListTable"
        :items="notes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros"
        class="position-relative"
      >
        <template #cell(reference)="data">
          <router-link :to="`/note-detail/${data.item.id}`">
            {{ data.item.reference }}
          </router-link>
        </template>

        <template #cell(type)="data">
          {{ data.item.type.toUpperCase() }}
        </template>

        <template #cell(created_at)="data">
          {{ dateFormat(data.item.created_at, "DD MMMM YYYY, h:m") }}
        </template>

        <template #cell(hacienda)="data">
          <status-hacienda :status_hacienda="data.item.hacienda" />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap text-left ml-2">
            <b-overlay
              :show="note_action_execute == data.item.id"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-tooltip
                :target="`tooltip-pdf-${data.item.id}`"
                placement="left"
              >
                <span>Generar pdf</span>
              </b-tooltip>
              <feather-icon
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('generate_pdf_note')
                "
                :id="`tooltip-pdf-${data.item.id}`"
                icon="FileTextIcon"
                class="cursor-pointer mr-1"
                size="18"
                @click="generatePdf(data.item.id, data.item.reference)"
              />
            </b-overlay>

            <b-tooltip
              :target="`tooltip-mail-${data.item.id}`"
              placement="right"
            >
              <span>Enviar por correo</span>
            </b-tooltip>
            <feather-icon
              v-if="
                (user_state && user_state.role == 'admin') ||
                  permissions.includes('send_email_note')
              "
              :id="`tooltip-mail-${data.item.id}`"
              icon="MailIcon"
              class="cursor-pointer mr-1"
              size="18"
              @click="sendEmail(data.item)"
            />

            <b-dropdown variant="link" toggle-class="p-0" no-caret right>
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                  class="text-body"
                />
              </template>

              <b-dropdown-item
                v-if="
                  (user_state && user_state.role == 'admin') ||
                    permissions.includes('download_xmls_note')
                "
                @click="
                  downloadXml(data.item.key50digits, data.item.type, 'send')
                "
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">XML Enviado</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="
                  ((user_state && user_state.role == 'admin') ||
                    permissions.includes('download_xmls_note')) && ['aceptado', 'rechazado'].includes(data.item.hacienda)
                "
                @click="
                  downloadXml(data.item.key50digits, data.item.type, 'response')
                "
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">XML Respuesta</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted" v-if="totalRegisters > 0"
              >Mostrando {{ fromRegister }} a {{ toRegister }} de
              {{ totalRegisters }} registros
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="notes.length !== totalRegisters"
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- component send email document -->
    <b-sidebar
      v-model="showSendEmailModal"
      id="sidebar-right"
      title="Enviar correo"
      right
      shadow
      width="30%"
    >
      <div class="col-md-12">
        <form-document-email
          @sendEmail="emailSent"
          :document="email_document"
          :email="dataSendEmail"
          document_type="nc"
        />
      </div>
    </b-sidebar>
    <!-- component send email document -->
  </b-overlay>
</template>

<script>
import FormDocumentEmail from "@/components/FormDocumentEmail.vue";

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BTooltip,
  BSidebar
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { confirmAlert, downloadBlob } from "@/helpers";

import NoteService from "@/services/notes.service";
import utilitiesService from "@/services/utilities.service";

import moment from "moment";
moment.locale("es");
import { mapState } from "vuex";
export default {
  directives: {
    Ripple
  },
  components: {
    BTooltip,
    BDropdown,
    BDropdownItem,
    vSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BOverlay,
    FormDocumentEmail,
    BSidebar
  },
  computed: {
    ...mapState({
      user_state: state => state.app.user,
      permissions: state => state.app.permissions
    })
  },
  data() {
    return {
      showSendEmailModal: false,
      email_document: null,
      note_action_execute: "",
      loading: false,
      perPage: 10,
      perPageOptions: [10, 20, 30, 40, 50, 100],
      search: "",
      currentPage: 1,
      lastPage: 1,
      totalRegisters: 1,
      toRegister: 10,
      fromRegister: 1,
      flagEdit: false,
      storeEditNote: false,

      notes: [],
      tableColumns: [
        { key: "reference", label: "Consecutivo" },
        { key: "type", label: "Tipo" },
        { key: "created_at", label: "Fecha", sortable: true },
        { key: "client.full_name", label: "Cliente", sortable: true },
        { key: "hacienda", label: "Hacienda", sortable: true },
        { key: "actions", label: "Acciones", tdClass: "p-0 pr-1" }
      ],

      dataSendEmail: {
        subject: "",
        content: "",
        to: ''
      },
    };
  },
  watch: {
    storeEditService(newval) {
      if (!newval) {
        this.flagEdit = false;
        this.service = { ...this.$options.data().service };
      }
    },
    currentPage(newval) {
      this.get(newval);
    },
    perPage() {
      this.get(this.currentPage);
    }
  },
  methods: {
    emailSent() {
      this.dataSendEmail = {
        subject: "",
        content: "",
        to: ''
      }
      this.email_document = null
      this.showSendEmailModal = false
    },
    sendEmail(doc) {
      this.email_document = doc;
      this.dataSendEmail.to = doc.client.email
      this.showSendEmailModal = true;
    },
    async generatePdf(note_id, reference) {
      try {
        this.note_action_execute = note_id;

        const { data: res } = await NoteService.generatePdf(note_id);

        this.downloadFile(res, `Nota-${reference}.pdf`);
        this.note_action_execute = "";
      } catch (e) {
        this.note_action_execute = "";
      }
    },
    downloadFile(file, namefile) {
      return downloadBlob(file, namefile);
    },
    downloadXml(key50digits, document_type, folder) {
      utilitiesService
        .downloadXml(key50digits, document_type, folder)
        .then(response => {
          downloadBlob(response.data, `${key50digits}.xml`);
        })
        .catch(error => {
          alert("error");
        });
    },
    dateFormat(date, format = "DD/MM/YYYY") {
      return moment(date).format(format);
    },
    async get(page = 1, searchQuery = {}) {
      try {
        this.loading = true;
        searchQuery.perPage = this.perPage;
        searchQuery.term = this.search;
        const { data: res } = await NoteService.getNotes(page, searchQuery);
        this.notes = res.data.data;
        this.loading = false;
        //Pagination
        this.lastPage = res.data.last_page;
        this.toRegister = res.data.to;
        this.fromRegister = res.data.from;

        this.totalRegisters = res.data.total;
      } catch (error) {
        this.loading = false;
      }
    }
  },
  created() {
    this.get();
  }
};
</script>
